import Vue from "vue";
import VueRouter from "vue-router";
const index = (resolve) => require(["../views/index.vue"], resolve);
import store from "@/store/index";
import { getCurrentRouters } from "@/utils/xxx";
const homePage = (resolve) => require(["../views/home/homePage.vue"], resolve);
const jxsMgr = (resolve) =>
  require(["../views/administrator/jxsMgr/jxsMgr.vue"], resolve);
const login = (resolve) => require(["../views/login.vue"], resolve);

const sales = (resolve) =>
  require(["../views/statement/Sales/index.vue"], resolve);
const CallBack = (resolve) =>
  require(["../views/statement/CallBack/index.vue"], resolve);
const IncomeStatement = (resolve) =>
  require(["../views/statement/IncomeStatement/index.vue"], resolve);
const Jurisdiction = (resolve) =>
  require(["../views/administrator/jurisdiction/index.vue"], resolve);
const Service = (resolve) =>
  require(["../views/statement/servicement/index.vue"], resolve);
const administrator = (resolve) =>
  require(["../views/administrator/index.vue"], resolve);
const statement = (resolve) =>
  require(["../views/statement/index.vue"], resolve);
const NotFound = (resolve) => require(["../views/NotFound.vue"], resolve);
const finance = (resolve) => require(["../views/finance/index.vue"], resolve);
const dealList = (resolve) =>
  require(["../views/finance/dealList/index.vue"], resolve);
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: login,
  },

  {
    path: "/login",
    name: "login",
    component: login,
    meta: { title: "登录", keepAlive: true },
  },
];

let dynamicRoutes = [
  {
    path: "/index",
    name: "index",
    component: index,
    redirect: { name: "homePage" },
    children: [
      {
        path: "/homePage",
        name: "homePage",
        component: homePage,
        meta: { title: "首页中心" },
      },
      {
        path: "/administrator",
        name: "administrator",
        component: administrator,
        meta: { title: "管理员", id: "1" },
        children: [
          {
            path: "/dealer",
            name: "dealer",
            component: jxsMgr,
            meta: { title: "经销商管理", id: "11" },
          },
          {
            path: "/jurisdiction",
            name: "jurisdiction",
            component: Jurisdiction,
            meta: { title: "员工管理", id: "12" },
          },
        ],
      },
      {
        path: "/statement",
        name: "statement",
        component: statement,
        meta: { title: "数据中心", id: "2" },
        children: [
          {
            path: "/sales",
            name: "sales",
            component: sales,
            meta: { title: "机构数据", id: "21" },
          },
          {
            path: "/callBack",
            name: "callBack",
            component: CallBack,
            meta: { title: "客户回款报表", id: "22" },
          },
          {
            path: "/incomeStatement",
            name: "incomeStatement",
            component: IncomeStatement,
            meta: { title: "收支明细报表", id: "23" },
          },
          {
            path: "/service",
            name: "service",
            component: Service,
            meta: { title: "售后工单报表", id: "24" },
          },
        ],
      },
      {
        path: "/finance",
        name: "finance",
        component: finance,
        meta: { title: "财务中心", id: "3" },
        children: [
          {
            path: "/dealList",
            name: "dealList",
            component: dealList,
            meta: { title: "收支明细", icon: "el-icon-s-marketing", id: "31" },
          },
        ],
      },
    ],
  },
];

let aaaRoutes = [
  {
    path: "/index",
    name: "index",
    component: index,
    redirect: "/homePage",
    children: [],
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem("token");
  if (token) {
    if (!store.state.isAuth) {
      await store.dispatch("getMenu");
      let list = await store.state.menuList;
      let children = getCurrentRouters(dynamicRoutes[0].children, list);
      aaaRoutes[0].children = children;
      aaaRoutes.push({
        path: "*",
        name: "404",
        component: NotFound,
      });
      console.log(1111111111, aaaRoutes);
      aaaRoutes.forEach((a) => {
        router.addRoute(a);
      });
      next("/index");
    }
    next();
  } else if (to.path === "/") {
    next();
  } else {
    next("/");
  }
});
export default router;
