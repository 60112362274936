import req from "@/utils/request";

// 密码登录
export function login(query) {
  return req.post("/manager/password/login", query);
}

// 验证码登录
export function verifyCode(query) {
  return req.post("/manager/verifyCode/login", query);
}

// 获取手机验证码
export function sms(query) {
  return req.post("/manager/sms", query);
}

// 退出登录
export function logout(query) {
  return req.post("/manager/logout", query);
}

// 经销商列表
export function getDealerList(query) {
  return req.post("/manager/dealer/getDealerList", query);
}

// 新增经销商
export function insertDealer(query) {
  return req.post("/manager/dealer/insertDealer", query);
}

// 上传图片
export function file(query) {
  return req.post("/manager/file", query);
}

// 删除经销商
export function deleteUser(query) {
  return req.post("/manager/dealer/deleteUser", query);
}

// 查询经销商
export function getUserById(query) {
  return req.post("/manager/dealer/getDealerById", query);
}

// 修改经销商
export function updateDealer(query) {
  return req.post("/manager/dealer/updateDealer", query);
}

// 员工列表
export function getUserList(query) {
  return req.post("/manager/user/getUserList", query);
}

// 添加员工
export function insertUser(query) {
  return req.post("/manager/user/insertUser", query);
}

// 修改员工
export function updateUser(query) {
  return req.post("/manager/user/updateUser", query);
}

// 查看员工
export function usergetUserById(query) {
  return req.post("/manager/user/getUserById", query);
}

// 删除员工
export function userdeleteUser(query) {
  return req.post("/manager/user/deleteUser", query);
}

// 重置员工密码
export function resetPassword(query) {
  return req.post("/manager/user/resetPassword", query);
}

// 销售数量报表
export function getSellQuantityRecord(query) {
  return req.post("/manager/report/getSellQuantityRecord", query);
}

// 销售金额报表
export function getSellAmountRecord(query) {
  return req.post("/manager/report/getSellAmountRecord", query);
}

// 查询所有经销商
export function getAllDealerList(query) {
  return req.post("/manager/dealer/getAllDealerList", query);
}

// 查询经销商员工
export function getAllDealerUserList(query) {
  return req.post("/manager/dealerUser/getAllDealerUserList", query);
}

// 查询经销商客户
export function getAllCustomerList(query) {
  return req.post("/manager/dealerCustomer/getAllCustomerList", query);
}

// 客户回款报表
export function getCustomerAmountRecord(query) {
  return req.post("/manager/report/getCustomerAmountRecord", query);
}

// 收支明细报表
export function getFinanceAmountRecord(query) {
  return req.post("/manager/report/getFinanceAmountRecord", query);
}

// 售后工单报表
export function getPostOrderRecord(query) {
  return req.post("/manager/report/getPostOrderRecord", query);
}

// 获取权限菜单
export function getCurrentRouter(query) {
  return req.post("/manager/user/getCurrentRouter", query);
}

// 获取当前管理员权限菜单
export function getCurrentInfo(query) {
  return req.post("/manager/user/getCurrentInfo", query);
}

// 删除收支
export function deleteFinance(query) {
  return req.post("/manager/dealerFinance/deleteFinance", query);
}

// 列表
export function getFinanceList(query) {
  return req.post("/manager/dealerFinance/getFinanceList", query);
}

// 新增
export function insertFinance(query) {
  return req.post("/manager/dealerFinance/insertFinance", query);
}

// 修改
export function updateFinance(query) {
  return req.post("/manager/dealerFinance/updateFinance", query);
}

// 首页
export function getIndexReport(query) {
  return req.post("/manager/report/getIndexReport", query);
}

// 月度汇总报表
export function getSellMonthRecord(query) {
  return req.post("/manager/report/getSellMonthRecord", query);
}
