<template>
  <el-cascader
      ref="cascader"
      size="small"
      style="width: 100%"
      v-model="cascaderInput"
      :options="options"
      @change="handleChange"
      :props='{
      value: "id",
      label: "name",
      children: "children",
      emitPath: false,
      checkStrictly: checkStrictly
    }'
  ></el-cascader>
</template>
<script>

export default {
  data() {
    return {
      cascaderInput: "",
      options: [],
    };
  },
  props: {
    checkStrictly: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    busmodelDictionary().then((res) => {
      this.options = res.data;
    });
  },
  methods: {
    handleChange(value) {
      let item;
      let selectedItems = this.$refs.cascader.getCheckedNodes(true);
      if(selectedItems && selectedItems.length > 0){
        item = selectedItems[0].data;
      }
      this.cascaderInput = value;
      this.getCader(item)
    },
    getCader(item) {
      this.$emit('getCader', item)
    }
  },
};
</script>
<style lang="less" scoped></style>
