export const getCurrentRouters = (data, list) => {
  let routes = data.filter(
    (v) => list.includes(v.meta.id) || v.name == "homePage"
  );
  let arr = [];
  if (routes) {
    function deepClone1(obj) {
      var objClone = Array.isArray(obj) ? [] : {};
      if (obj && typeof obj === "object") {
        for (let key in obj) {
          if (obj.hasOwnProperty(key)) {
            if (obj[key] && typeof obj[key] === "object") {
              objClone[key] = deepClone1(obj[key]);
            } else {
              objClone[key] = obj[key];
            }
          }
        }
      }
      return objClone;
    }
    arr = deepClone1(routes);
    arr.forEach((res) => {
      if (res.name !== "homePage") {
        res.children = [];
      }
    });
    routes.forEach((v, b) => {
      if (v.children) {
        v.children.forEach((a, i) => {
          if (list.includes(a.meta.id)) {
            arr[b].children.push(a);
          }
        });
      }
    });
  }
  return arr;
};
