<template>
  <el-table
      style="width: 100%"
      v-on="$listeners"
      :data="tableData"
      :row-class-name="rowClassName"
      v-loading="loading"
      :height="height"
      :max-height="maxHeight"
      @row-click="rowClick"
      :highlight-current-row="true"
      :row-style="rowStyle"
      @cell-mouse-enter="mouseEnter"
      @cell-mouse-leave="mouseLeave"
      @cell-click="cellClick"
      @expand-change="expandChange"
      @selection-change="handleSelectionChange"
      @select="handleSelect"
      @select-all="handleSelectAll"
      @sort-change="sortChange"
      :default-sort="defaultSort"
      :expand-row-keys="expands"
      :border="showBorder"
      :stripe="stripe"
      :row-key="getRowKeys"
      ref="table"
      :show-header="showHeader"
      tooltip-effect="dark"
      class="main-table"
      :cell-style="cellStyle"
      :header-cell-style="
      this.$store.state.theme
        ? { 'background-color': '#fff' }
        : { 'background-color': '#171719' }
    "
  >
    <!-- 数据为空样式通用 -->
    <template slot="empty" v-if="isEmpty">
      <el-empty description="暂无数据"></el-empty>
    </template>
    <!--多选框-->
    <el-table-column
        v-if="showSelect"
        type="selection"
        fixed
        :selectable="selectable"
        :reserve-selection="reserveSelection"
        width="65"
        align="center"
    >
    </el-table-column>
    <!-- 序号 -->
    <el-table-column
        v-if="showIndex"
        type="index"
        label="序号"
        :width="indexWidth()"
        fixed
        :render-header="renderheader"
        :index="tableIndex"
    >
    </el-table-column>
    <!-- 展开 -->
    <el-table-column align="center" v-if="expand === true" type="expand">
      <template slot-scope="expanded">
        <slot name="expanded" :data="expanded.row"></slot>
      </template>
    </el-table-column>
    <!-- 通用列 -->
    <template v-for="(item, index) in columnData">
      <!-- 需要在所用页面定制修改列头的 -->
      <slot v-if="item.slot === 'header'" :name="item.prop"/>
      <!-- 通用列 -->
      <el-table-column
          v-if="!item.slot"
          :label="item.label"
          :prop="item.prop"
          :key="item.label"
          :sortable="item.sortable || false"
          :align="item.align ? item.align : 'left'"
          :fixed="item.fixed ? item.fixed : false"
          :show-overflow-tooltip="tooltip"
          :width="item.width"
          :min-width="item.minWidth"
      >
        <template slot-scope="scope">
          {{
            item.filters != undefined
                ? filterType(item.filters, scope.row[item.filters.prop])
                : nullTextFilter(scope.row[item.prop])
          }}
        </template>
      </el-table-column>

      <!-- 需要在所用页面定制修改的;比如带样式的表格内容以及操作栏 -->
      <slot v-if="item.slot === 'outer'" :name="item.prop" :option="item"/>
    </template>
  </el-table>
</template>
<script type="text/ecmascript-6">
// columnData: [
//   {
//     label: '警情编号', // 表头
//     prop: 'DataTag',  // 参数名
//     colType: 'common'  // 通用列
//     width: '100' // 列表宽度
//   },
//   {
//     label: '结束时间',
//     prop: 'errorStr',
//     slot: 'header',  // 外部定制头部
//     slotname: 'errorStr', // 插槽名字
//   }
// ],
import {mapActions} from "vuex";

export default {
  name: 'Table',
  data() {
    return {
      filter: {}
    }
  },
  props: {
    tableData: {
      // table 中的数据
      type: Array,
      required: true
    },
    columnData: {
      // 列表项
      type: Array,
      required: true
    },
    loading: {
      // table 数据加载状态
      type: Boolean,
      required: true
    },
    tooltip: {
      type: Boolean,
      default: true
    }, // 字数太多用省略号并显示全部文字
    height: [Number, String], // 表格高度
    maxHeight: [Number, String],
    showIndex: {
      type: Boolean,
      default: false
    }, // 是否显示序号
    showSelect: {
      type: Boolean,
      default: false
    }, // 是否显示选择框
    expand: {
      // 是否有展开项
      type: Boolean,
      default: false
    },
    showHeader: {
      // 是否显示表格头部
      type: Boolean,
      default: true
    },
    showBorder: {
      // 是否显示边框
      type: Boolean,
      default: false
    },
    stripe: {
      // 是否显示斑马纹
      type: Boolean,
      default: false
    },
    expands: {
      // 展开项中的展开id
      type: Array
    },
    getRowKeys: Function, // 展开时当前展开项id
    selectable: Function,
    reserveSelection: {
      type: Boolean,
      default: false
    }, // 翻页记忆
    isEmpty: {
      type: Boolean,
      default: true
    },
    fixed: {
      type: [Boolean, String],
      default: false
    }, // 固定列
    page: { // 序号分页连续需要
      type: Number,
      default: undefined
    },
    limit: { // 序号分页连续需要
      type: Number,
      default: undefined
    },
    rowClassName: {
      type: Function,
    },
    defaultSort: Object,
  },
  computed: {
    elTableInstance() {
      return this.$refs.table;
    },
  },
  created() {

  },
  mounted() {
    if (this.fixed) {
      window.onresize = () => {
        this.$nextTick(() => {
          if (this.$refs && this.$refs.table) {
            this.$refs.table.doLayout()
          }
        })
      }
    }
  },
  methods: {
    ...mapActions(["changeThemeStyle"]),
    cellStyle() {
      if (!this.$store.state.theme) {
        return "background-color: #171719";
      }
    },
    tableIndex(index) {
      if (this.page) {
        const tableIndex = (this.page - 1) * this.limit + index + 1
        return String(tableIndex).length === 1 ? '0' + tableIndex : tableIndex
      } else {
        return index + 1
      }
    },
    /**
     * 使用span标签包裹内容，然后计算span的宽度 width： px
     * @param {*} str
     */
    getTextWidth(str) {
      let width = 0;
      let html = document.createElement('span');
      html.innerText = str;
      html.className = 'getTextWidth';
      document.querySelector('body').appendChild(html);
      width = document.querySelector('.getTextWidth').offsetWidth;
      document.querySelector('.getTextWidth').remove();
      return width + 70;
    },
    indexWidth() { // 索引列宽度
      const tableIndex = (this.page - 1) * this.limit + this.tableData.length
      const width = this.getTextWidth(String(tableIndex).length === 1 ? '0' + tableIndex : tableIndex)
      return width
    },
    renderheader(h, {column, $index}) {
      return h('span', {}, [
        h('span', {}, column.label.split('/')[0]),
        h('br'),
        h('span', {}, column.label.split('/')[1])
      ])
    },
    tableHeaderColor(row, column, rowIndex, columnIndex) {
      this.$emit('tableHeaderColor', {row, column, rowIndex, columnIndex})
    },
    // 点击当前行事件
    rowClick(row) {
      this.$emit('rowClick', row)
    },
    rowStyle({row, rowIndex}) {
      this.$emit('rowStyle', {row, rowIndex})
    },
    // 鼠标进入事件
    mouseEnter(row, column, cell, event) {
      this.$emit('mouseEnter', {row, column, cell, event})
    },
    // 鼠标离开事件
    mouseLeave(row, column, cell, event) {
      this.$emit('mouseLeave', {row, column, cell, event})
    },
    // 点击单元格事件
    cellClick(row, column, cell, event) {
      this.$emit('cellClick', {row, column, cell, event})
    },
    // 展开事件
    expandChange(row, expandedRows) {
      this.$emit('expand-change', {row, expandedRows}, true)
    },
    handleSelectionChange(val) {
      // console.log(val, '---->val')
      this.$emit('select-change', val)
    },
    handleSelect(selection, row) {
      this.$emit('select', {selection, row})
    },
    handleSelectAll(selection) {
      this.$emit('selectAll', selection)
    },
    sortChange({column, prop, order}) {
      this.$emit('sort', {column, prop, order})
      this.$emit('sort-change', {column, prop, order});
      // console.log(column)
      // console.log(prop)
      // console.log(order)
    },
    clearSelect() {
      this.$refs.table.clearSelection();
    },
    toggleRowSelection(data) {
      this.$refs.table.toggleRowSelection(data)
    },
    filterType(val, key) {
      let res = ''
      if (val.code) {
        val.code.map(item => {
          if (item.dictValue === key) {
            res = item.dictLabel
          }
        })
      }
      // this.refs.table.doLayout()
      return res || '--'
    },
    nullTextFilter(val) {
      return val || val == 0 ? val : '--'
    }
  }
}
</script>
<style scoped>
/*@import url("../../../static/css/tablepage.css");*/
</style>
<style lang="less" scoped>
.tableComponent {
  .tableWrapper {
    margin: 20px 0;
  }

  /deep/ .el-checkbox {
    margin-bottom: 0;
  }

  /deep/ .el-tooltip__popper {
    max-width: 300px !important;
  }
}

.main-table {
  color: #8a93a6;

  .edit {
    cursor: pointer;

    &:nth-child(2) {
      margin: 0 20px;
    }

    i {
      margin-right: 5px;
    }
  }

  width: 100%;
}

/deep/ .el-table__body-wrapper {
  overflow: auto !important;
}
</style>
