// 分页
<template>
	<el-pagination
		@size-change="handleSizeChange"
		@current-change="handleCurrentChange"
		background
		layout="sizes,total,prev, pager, next"
		:page-sizes="[10, 20, 50, 100]"
		:current-page="pages.current"
		:page-size="pages.size"
		:total="pages.total"
	>
	</el-pagination>
</template>
<script>
export default {
	props: {
		pages: {
			type: Object,
			default: false
		}
	},
	data() {
		return {};
	},
	methods: {
		handleSizeChange(val) {
			let data = {
        current: this.pages.current,
				size: val
			};
			this.$emit("pagination", data);
		},
		handleCurrentChange(val) {
      let data = {
				current: val,
				size: this.pages.size
			};
			this.$emit("pagination", data);
		}
	}
};
</script>
<style scoped>
/deep/ .el-pagination__sizes {
	width: 100px;
}
</style>
