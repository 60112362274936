// 注册全局组件

import UserTable from './table/UserTable.vue'
import caderPro from './cascaderProducts/caderPro'
import Pagination  from './Pagination'
function plugin(Vue) {
    if (plugin.installed) {
        return
    }
    Vue.component("cader-pro", caderPro);
    Vue.component("user-table", UserTable);
    Vue.component("user-page", Pagination);
}


export default plugin
