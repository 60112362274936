import axios from "axios";
import CryptoJS from "crypto-js";
import { Message } from "element-ui";

// /boyandms/manager/
axios.defaults.baseURL = "/";
// axios.defaults.baseURL = "http://192.168.216.105:58030";
// axios.defaults.baseURL = "https://manager.boyanmusic.com";
axios.defaults.timeout = 30000;
// 请求拦截器
axios.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  const sign = localStorage.getItem("sign");
  config.headers.sign = sign;
  if (token) {
    config.headers.Authorization = token;
  }
  return config;
});

// 响应拦截器
axios.interceptors.response.use(
  (res) => {
    if (res.data.code == 401) {
      Message({
        type: "warning",
        message: res.data.msg,
      });
      localStorage.clear();
      router.push("/login");
    }
    return res;
  },
  (error) => {
    // console.log(error.code)
    return error;
  }
);

export default {
  get(url, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(url, { params })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          // 处理失败的请求
          console.log(err);
        });
    });
  },
  post(url, query) {
    const obj = { ...query };
    obj.signKey = "ZnJhbWUtZW5jcnlwdC1rZXk=";
    const arr = [];
    for (const k in obj) {
      if (k === "idList") {
        let v = "[";
        obj[k].forEach((res) => {
          v = v + '"' + res + '"' + ",";
        });
        v = v.substring(0, v.length - 1);
        arr.push(k + "=" + v + "]");
      } else {
        arr.push(k + "=" + obj[k]);
      }
    }
    const sign = CryptoJS.SHA256(arr.sort().join("&"));
    localStorage.setItem("sign", sign);
    return new Promise((resolve, reject) => {
      axios
        .post(url, query)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  },
  delete(url, params) {
    return new Promise((resolve, reject) => {
      axios
        .delete(url, { params })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          // 处理失败的请求
          console.log(err);
        });
    });
  },
};
